import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ActivitiesSlider from "../components/activities-slider";
import BookingBanner from "../components/booking-banner";

const ActivityTemplate = ({ data, pageContext: context }) => {
  const { title, seo_title, thumbnail, content, guide } =
    data.contentfulActivite;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;

  function getParentPage() {
    return allPages.find((page) => page.category === "Carnets de voyages");
  }

  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2-editorial">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3-editorial">{children}</h3>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => (
        <BookingBanner title={node.data.target.title} />
      ),
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <Layout>
      <Seo title={seo_title || title} image={thumbnail && thumbnail.file.url}>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "${getParentPage().title}",
                  "item": "${siteUrl}/${context.locale}/${
            getParentPage().slug
          }/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${title}"
                }
              ]
            }
          `}
        </script>
      </Seo>
      <article>
        <section className="section section-white">
          <div
            className={`introduction${
              thumbnail ? " introduction-hero" : " introduction-clouds"
            }`}
          >
            <div className="introduction-container">
              <div className="introduction-inner">
                {thumbnail && (
                  <Img
                    className="introduction-image"
                    fluid={thumbnail.fluid}
                    loading="eager"
                    alt={title}
                  />
                )}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <p className="eyebrow">{guide.title}</p>
                      <div className="activity-heading">
                        <p>{title}</p>
                        <h1 className="lead">{seo_title}</h1>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-gradient overflow-hidden">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                {renderRichText(content, renderOptions)}
              </div>
            </div>
            <aside className="mt-16 mt-md-30">
              <h2>
                <FormattedMessage id="guide.activity" />
              </h2>
              <div className="row mt-8 mt-md-10">
                <div className="col-8 col-sm-10 col-lg-12 position-static">
                  <LazyLoad>
                    <ActivitiesSlider />
                  </LazyLoad>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default ActivityTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      meta_description
      address
      phone
      booking_id
      place_id
      business_url
      hero_image {
        file {
          url
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulActivite(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      seo_title
      guide {
        title
      }
      thumbnail {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        raw
        references {
          title
          ... on ContentfulBanniereDeReservation {
            contentful_id
            __typename
            title
          }
        }
      }
    }
    allContentfulActivite(
      filter: {
        hotel: { slug: { eq: $hotel } }
        node_locale: { eq: $locale }
        slug: { ne: $slug }
      }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        node_locale
      }
    }
  }
`;
