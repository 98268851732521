import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";

import Context from "./context";
import Search from "./search";
import Close from "./close";

const BookingBanner = ({ title }) => {
  const [search, setSearch] = useState("");
  const { data } = useContext(Context);
  const intl = useIntl();
  const booking_id = data.contentfulHotel.booking_id;

  function toggleSearch() {
    setSearch(search === "" ? " active" : "");
  }

  return (
    <aside className="booking-banner">
      <h2 className="text-white">{title}</h2>
      <div className={`modal modal-search${search}`}>
        <button className="button" onClick={toggleSearch}>
          {intl.formatMessage({ id: "book" })}
        </button>
        <div className="modal-content">
          <button
            className="modal-close"
            aria-label={intl.formatMessage({ id: "close" })}
            onClick={toggleSearch}
          >
            <Close />
          </button>
          <div className="container">
            <Search booking_id={booking_id} />
          </div>
        </div>
        <button
          className="modal-backdrop"
          aria-label={intl.formatMessage({ id: "close" })}
          onClick={toggleSearch}
        ></button>
      </div>
    </aside>
  );
};

export default BookingBanner;
