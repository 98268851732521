import React, { Component } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Slider from "react-slick";

import Context from "./context";
import PreviousButton from "./previous-button";
import NextButton from "./next-button";

class ActivitiesSlider extends Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }

  previous() {
    this.slider.slickPrev();
  }

  render() {
    const { data } = this.context;
    const allPages = data.allContentfulPage.nodes;

    const settings = {
      infinite: false,
      slidesToShow: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 575.98,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991.98,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 1199.98,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
          },
        },
      ],
    };

    function getParentSlug() {
      return allPages.find((page) => page.category === "Carnets de voyages")
        .slug;
    }

    return (
      <>
        <PreviousButton onClick={this.previous} />
        <NextButton onClick={this.next} />
        <Slider
          className="slider slider-thumbnails"
          ref={(ref) => (this.slider = ref)}
          {...settings}
        >
          {data.allContentfulActivite.nodes.map((node, i) => (
            <Link
              className="slider-thumbnails-item"
              to={`/${node.node_locale}/${getParentSlug()}/${node.slug}/`}
              key={i}
            >
              {node.thumbnail && (
                <Img
                  className="slider-thumbnails-item-image"
                  fluid={node.thumbnail.fluid}
                  alt={node.title}
                />
              )}
              <h3>{node.title}</h3>
            </Link>
          ))}
        </Slider>
      </>
    );
  }
}

export default ActivitiesSlider;
